@import 'styles/theme.scss';

.imgContainer {
  width: 100%;
  position: relative;
  text-align: center;
  color: $white;
}

.img {
  width: 100%;
  margin: 0 auto;
}

.overlayImage {
  position: relative;
  left: 80px;
  top: 90px;
}

.textContainer {
  z-index: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
}

.centeredText {
  min-width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  & > * {
    font-weight: $font-weight-600 !important;
  }
}

.overlayCenteredTextInBox {
  position: absolute;
  background: $gradient-background3;
  padding: 1.6rem;
  width: 324px;
  min-height: 304px;
  border-radius: 3px;
  top: 70%;
  text-align: start;
  font-weight: $font-weight-700;
}

.finePrint {
  @media (max-width: $lg) {
    visibility: hidden;
  }
}

.whiteText {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: $white;
}

.greyText {
  position: absolute;
  bottom: -300px;
  color: $font-color;
}

.hidden {
  display: none;
}

.centeredImg {
  width: 60%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.droplets {
  position: absolute;
  top: 42%;
  left: 45%;
  z-index: 0;
  width: 80%;
  transform: translate(-50%, -50%);
}

@media (max-width: $md) {
  .imgContainer {
    display: none;
  }
}

.whiteBackground {
  background: $white;
  color: $primary-dark;
  :global {
    .MuiTypography-caption {
      color: $secondary-main;
    }
  }
}

.paleBlueText {
  color: $primary-light;
}
