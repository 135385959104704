@import 'styles/theme.scss';

.content {
  background-color: $primary-dark;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: $white;
  padding: 0 80px;
  height: 400px;

  @media (max-width: $xs-max) {
    padding: 0 20px;
    min-height: 324px;
    height: 100%;
    :global {
      .MuiButton-containedSecondary {
        margin: 0 0 20px;
      }
    }
  }

  .title {
    color: $white;
    margin: 16px 0 0;
    text-align: center;

    @media (max-width: $xs-max) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  .blurb {
    color: $white;
    margin-top: 6px;

    ol,
    ul {
      text-align: left;
      color: $white;
      margin: 0;
      padding-bottom: 10px;
    }

    p {
      color: $white;
      margin: 0;
      padding-bottom: 10px;
    }

    @media (max-width: $xs-max) {
      font-size: 10px;
      line-height: 15px;
      text-align: center;
      margin-bottom: 24px;
    }
  }

  &.lightBackground {
    background-color: $primary-100;

    .title {
      color: $primary-dark;
    }

    .blurb {
      color: $font-color;

      ol,
      ul,
      p {
        color: $font-color;
      }
    }
  }
}

.img {
  object-fit: cover;
  width: 450px;
  min-height: 400px !important;
  margin: 0 !important;

  @media (max-width: $xs-max) {
    width: 100%;
    min-height: 324px !important;
  }
}

.leftImage {
  flex-direction: row-reverse;
  @media (max-width: $xs-max) {
    flex-direction: column-reverse;
  }
}

.imageContainer {
  position: relative;
}
.imageWrapper {
  height: 324px;
}
