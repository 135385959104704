@import 'styles/theme.scss';

.itemContent {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: $md) {
    display: block;
  }

  a {
    line-height: 24px;
    font-size: 18px;
    color: $secondary-main;
    text-decoration: none;
    font-weight: $font-weight-400;
    * {
      vertical-align: middle;
    }
    svg {
      font-size: 20px;
      margin-left: 6px;
    }
  }
}

.itemContainer {
  * {
    margin: 0;
  }
  p {
    padding-bottom: 10px;
  }
  ol,
  ul {
    padding-bottom: 10px;
  }
}
.img {
  object-fit: cover;
}

.heading {
  h5 {
    color: $primary-dark;
    margin-bottom: 24px;
  }
}

.Three_Column {
  .imgWrapper {
    height: 160px;
    @media (max-width: $md) {
      height: 224px;
      > div {
        max-width: calc(100% - 16px);
      }
      div {
        height: 224px !important;
        max-width: 100%;
      }
    }
  }
}

.Two_Column {
  .imgWrapper {
    height: 216px;
    @media (max-width: $md) {
      height: 224px;
      > div {
        max-width: calc(100% - 16px);
      }
      div {
        height: 224px !important;
        max-width: 100%;
      }
    }
  }
}

.One_Column {
  width: 83%;
  margin: 0 auto;
  :global {
    .MuiGrid-spacing-xs-3 > .MuiGrid-root {
      padding-top: 0;
      padding-bottom: 0;
    }
    .MuiButton-containedSecondary {
      margin: 24px 0 0 0;
    }
  }
  @media (max-width: $md) {
    width: 100%;
    .title {
      margin: 16px 0;
    }
  }
}

.Full_Width {
  @media (max-width: $md) {
    .title {
      margin: 16px 0;
    }
  }
}

.title {
  color: $primary-dark;
  margin-top: 24px;
}

.blurb {
  padding: 8px 0;
  margin-top: 16px;
}

.linkText {
  color: $white;
  line-height: 16px;
}

.imgWrapper {
  position: relative;
  object-fit: cover;
  height: 304px;
  width: 100%;
}

.imageSlider {
  @media (max-width: $md) {
    > div {
      max-width: calc(100% - 16px);
      position: relative;
      > div {
        div:last-of-type {
          height: auto;
        }
      }
    }
    div {
      height: 304px;
      min-width: 100%;
      max-width: 100%;
    }
  }
}

.notImageSlider {
  @media (max-width: $sm) {
    height: 224px;
  }
}
